import actions from './actions';

const initState = {
  mqttClient: null,
};

export default function mqttReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_MQTT_CLIENT:
      return {
        ...state,
        mqttClient: action.client,
      };
    default:
      return state;
  }
}
