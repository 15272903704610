import { all, takeEvery, call, put, fork, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import actions from './actions';
import trxAction from '../transactions/actions';
import authAction from '../auth/actions';
import financialsAction from '../financials/actions';
import dashBoardAction from '../dashboard/actions';
import merchantAction from '../merchant/actions';

// import * as mqttPaho from 'paho.mqtt.js';
import * as mqtt from 'mqtt';
import { getEnv, getTopic } from '@iso/lib/helpers/utility';

const { messageArrived } = trxAction;
const hostMqtt1 = process.env.REACT_APP_MQTT_HOST1;
const hostMqtt2 = process.env.REACT_APP_MQTT_HOST2;
const protocol = process.env.REACT_APP_MQTT_PROTOCOL;
const port = process.env.REACT_APP_MQTT_PORT;
const username = process.env.REACT_APP_MQTT_USER;
const password = process.env.REACT_APP_MQTT_PASS;
const url = [
  {
    protocol,
    host: hostMqtt1,
    port,
  },
  {
    protocol,
    host: hostMqtt2,
    port,
  },
];
const options = {
  clientId: '',
  clean: true,
  keepalive: 60,
  connectTimeout: 4000,
  rejectUnauthorized: false,
  username,
  password,
  servers: url,
};

export function* newConnectToMQTT() {
  yield takeEvery('CONNECT_MQTT', function* ({payload}) {
    let topicsNeedToResubscribe
    try {
      let client
      const match = payload?.paramsId;
      const state = yield select();
      const mqttClient = yield select(state => state.MqttReducer.mqttClient);
      const identity = state.Auth.identity;
      const selectedMerchant = state.Auth.selectedMerchant;
      const role = identity?.role?.role?.label;
      const mid = state.Auth?.identity?.merchantId;
      const trxId = payload?.trxId;
      const dlRequest = payload?.dlRequest;
      const informationMerchantId = state.Merchant?.merchantInfo?.merchantId;
      const merchantId = selectedMerchant !== 'All Merchant' ? selectedMerchant : role === 'Administrator' ? null : identity?.merchantId ? identity.merchantId : null;
      const topic = getTopic().get('topic');
      const env = getEnv().get('env');
      const topicLogin = `/${env}/sessions/${identity?.username}`
      const topicWithdraw = `/withdraw/${mid}`;
      const topicWithdrawEmail = `/withdraw/email/${mid}`;
      const topicKycUpload = `/lambda-kyc-upload/${mid}`;
      const topicMobileAndroid = `/lambda-mobiletrx-${mid}`;
      const topicSplitPaymentTransaction = `/lambda-split-payment-transaction-${mid}`;
      const topicCcUpload = `/lambda-cc-upload/${mid}`;
      const topicShippingTimeline = `/lambda-courier-order-tracking/${mid}`;
      const topicCourierTracking = `/courier_order_tracking/${selectedMerchant}`;
      const topicLambdaCourierTracking = `/lambda-courier-order-tracking/${selectedMerchant}`;
      const topicsToSubscribe = [
        topic, topicLogin, topicWithdraw, topicWithdrawEmail, 
        topicKycUpload, topicMobileAndroid, topicSplitPaymentTransaction, 
        topicCcUpload, topicShippingTimeline, topicCourierTracking, topicLambdaCourierTracking
      ];
      if (identity){
        topicsNeedToResubscribe = payload ? [...topicsToSubscribe, payload.topic] : topicsToSubscribe;
      } else {
        topicsNeedToResubscribe = [payload.topic]
      }

      if (mqttClient?.connected) {
        const resubscribeTopics = yield call(resubscribeToMQTT, mqttClient, topicsNeedToResubscribe)
        if (!resubscribeTopics) return;
      } else {
        options.clientId = 'biz_ui_' + Math.random().toString(16).substr(2, 8);
        client = yield mqtt.connect(options);
      
        yield call(handleMqttConnection, client);

        client.subscribe(topicsNeedToResubscribe);

        yield put({
          type: actions.SET_MQTT_CLIENT,
          client: client,
        });
      }

      const eventResult = yield call(createMQTTEventHandlerNew, mqttClient ? mqttClient : client) 
      while (true) {
        try {
          // An error from socketChannel will cause the saga jump to the catch block
          const mqttEventChannelResult = yield take(eventResult)
          if (mqttEventChannelResult) {
            switch (mqttEventChannelResult.topic){
              case topicLogin:
                if (mqttEventChannelResult.payload.token) {
                  yield put({
                    type: authAction.LOGIN_VALIDATOR,
                    token: mqttEventChannelResult.payload.token,
                  });
                  yield localStorage.setItem('mqttToken', mqttEventChannelResult.payload.token);
                }
                break;
              case topicWithdraw:
                if (mqttEventChannelResult.payload.withdraw) {
                  // WITHDRAW MQTT
                  if (mqttEventChannelResult.payload.status_text) {
                    yield put({
                      type: financialsAction.VERIFIED_OTP,
                      payload: 'Your withdraw is currently processing!',
                      isErrorVerifyOtp: false,
                    });
                  } else {
                    yield put({
                      type: financialsAction.VERIFY_OTP_FAILED,
                    });
                    if (mqttEventChannelResult.payload.code) {
                      yield put({
                        type: financialsAction.VERIFIED_OTP,
                        payload: mqttEventChannelResult.payload.advise,
                        isErrorVerifyOtp: true,
                      });
                    } else if (mqttEventChannelResult.payload.response_code) {
                      yield put({
                        type: financialsAction.VERIFIED_OTP,
                        payload: mqttEventChannelResult.payload.response_message,
                        isErrorVerifyOtp: true,
                      });
                    }
                  }
                }
                break;
              case topicWithdrawEmail:
                // WITHDRAW EMAIL
                yield put({
                  type: financialsAction.EMAIL_NOTIF,
                  payload: mqttEventChannelResult.payload,
                });
                break;
              case topicKycUpload:
                if (mqttEventChannelResult.payload.kycApplication && mqttEventChannelResult.payload.kycDocs && mqttEventChannelResult.payload.kycQualified) {
                  const state = yield select();
                  const merchantId = role === 'Administrator' || role === 'Finance' || role === 'Sales' ? state.Auth.selectedMerchant : state.Auth.identity.merchantId;;
                  if (merchantId === mqttEventChannelResult.payload.kycQualified.merchantId) {
                    yield put({
                      type: merchantAction.KYC_MQTT,
                      kycApplication: mqttEventChannelResult.payload.kycApplication,
                      kycDocs: mqttEventChannelResult.payload.kycDocs,
                      kycQualified: mqttEventChannelResult.payload.kycQualified,
                    });
                  }
                }
                break;
              case topicCcUpload:
                yield put({
                  type: merchantAction.CC_MQTT,
                  ccApplication: mqttEventChannelResult.payload.ccApplication,
                  ccDocs: mqttEventChannelResult.payload.ccDocs,
                });
                break;
              case topicShippingTimeline:
                yield put({
                  type: trxAction.SHIPPING_TIMELINE_MQTT,
                  timeline: mqttEventChannelResult.payload,
                });
                break;
              case topicCourierTracking:
              case topicLambdaCourierTracking:
                if (mqttEventChannelResult.payload === 'SUCCESS_TRACKING') {
                  yield put({
                    type: trxAction.FETCH_SHIPPING_MQTT_RESULT,
                  });
                }
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic)
                break;
              case `/download/merchant/${process.env.REACT_APP_TOPIC_ENV}/${dlRequest}`:
                yield put({
                  type: merchantAction.DOWNLOAD_HISTORY_SUCCESS,
                  data: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();
                break;
              case `/download/merchant/store/${process.env.REACT_APP_TOPIC_ENV}/${dlRequest}` :
                yield put({
                  type: merchantAction.DOWNLOAD_STORE_TXN_SUCCESS,
                  data: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();               
                break;
              case `/upload_document/${informationMerchantId}`:
                if (mqttEventChannelResult.payload === 'SUCCESS_UPLOADING') {
                  yield put({
                    type: merchantAction.MQTT_POSTING_RESULT,
                  });
                }
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic) ;
                eventResult.close();  
                break;
              case `/download/withdrawal/${process.env.REACT_APP_TOPIC_ENV}/${dlRequest}`: 
                yield put({
                  type: financialsAction.DOWNLOAD_WITHDRAWAL_LOADED,
                  payload: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();
                break;
              case `/download/split/${process.env.REACT_APP_TOPIC_ENV}/${dlRequest}`:
                yield put({
                  type: financialsAction.DOWNLOAD_SPLIT_LOADED,
                  payload: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();                
                break;
              case `/download/topup/${process.env.REACT_APP_TOPIC_ENV}/${dlRequest}`:
                yield put({
                  type: financialsAction.DOWNLOAD_TOPUP_LOADED,
                  payload: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic)
                eventResult.close();
                break;
              case `/download/bizwalletreport/${process.env.REACT_APP_TOPIC_ENV}/${dlRequest}`:
                yield put({
                  type: financialsAction.DOWNLOADED_BIZWALLET_REPORT,
                  payload: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();
                break;
              case `/download/autosettlement/${process.env.REACT_APP_TOPIC_ENV}/${dlRequest}`: 
                yield put({
                  type: financialsAction.DOWNLOAD_AUTO_SETTLEMENT_LOADED,
                  payload: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();
                break;
              case `/email/${match}`:
                yield put({
                  type: authAction.REDIRECT_REGISTRATION_SUCCESS,
                  payload: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();
                break;
              case `/download/transaction/${process.env.REACT_APP_TOPIC_ENV}/${dlRequest}`:
                yield put({
                  type: trxAction.DOWNLOADED_TRANSACTION_PDF,
                  payload: mqttEventChannelResult.payload,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();
                break;
              case `/lambda-cc-upload/${informationMerchantId}`:
              case `/upload_cc_document/${informationMerchantId}`:
                if (mqttEventChannelResult.payload === 'SUCCESS_UPLOADING') {
                  yield put({
                    type: merchantAction.MQTT_CC_POSTING_RESULT,
                  });
                }
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic);
                eventResult.close();
                break;
              case `/lambda_fetch_transaction_details/${trxId}`:
                const finalBase64 = `data:${mqttEventChannelResult.payload.fileType};base64,${mqttEventChannelResult.payload.merchantLogo.base64}`;
                yield put({
                  type: trxAction.FETCHED_DETAILED_TRANSACTION_DATA,
                  payload: mqttEventChannelResult.payload,
                  merchantLogo: finalBase64,
                  hasMerchantLogo: mqttEventChannelResult.payload.merchantLogo.success,
                });
                yield call(handleMqttUnsubscribe, mqttClient ? mqttClient : client, 
                  mqttEventChannelResult.topic, payload.topic)
                eventResult.close();
                break;
              case `/store/pchannel/maintenance/${process.env.REACT_APP_TOPIC_ENV}/mqttpchannelmaintenancetopic123`:
                const pchannels = []
                const nonbankOTC = []
                mqttEventChannelResult.payload.forEach((e) => {
                  if (e.pmethod !== "nonbank_otc") {
                    pchannels.push(e);
                  } else {
                    nonbankOTC.push(e);
                  }
                })
                yield put({
                  type: merchantAction.FETCH_MAINTENANCE_PCHANNELS_SUCCESS,
                  payload: {
                    pchannels: pchannels,
                    nonbankOTC: nonbankOTC
                  },
                });
                break;
              case `/disbursement/supplierInfo/${process.env.REACT_APP_TOPIC_ENV}/${merchantId}/`:
                const state = yield select();
                const suppliersArray = state.Transactions.filteredSuppliersArray;
                const fetchedSupplierInfo = state.Transactions.supplierInformationSuccess;

                const { supplierinformation } = mqttEventChannelResult.payload.data;
                const filteredSuppliersArray = suppliersArray?.filter(item => item.Id !== supplierinformation.Id);
                const filteredFetchedSupplierInfo = fetchedSupplierInfo?.filter(item => item.Id !== supplierinformation.Id);
                const isSupplierExists = suppliersArray?.find(item => item.Id === supplierinformation.Id);
                // Add new or updated supplier in filtered supplier information
                const newFilteredSuppliersArray = [supplierinformation, ...filteredSuppliersArray];
                // Add new or updated supplier in fetched supplier information
                const newFilteredSupplierInfo = [supplierinformation, ...filteredFetchedSupplierInfo];
                yield put({
                  type: trxAction.SET_FILTERED_SUPPLIERS_ARRAY,
                  payload: newFilteredSuppliersArray,
                })
                yield put({
                  type: trxAction.FETCH_SUPPLIER_INFO_SUCCESS,
                  payload: newFilteredSupplierInfo,
                })
                // Check if the supplier exist to determine if the process is create or update
                if(isSupplierExists) {
                  yield put({
                    type: trxAction.UPDATE_SUPPLIER_INFO_SUCCESS,
                    payload: mqttEventChannelResult.payload,
                  })
                } else {
                  yield put({
                    type: trxAction.SAVE_SUPPLIER_INFO_SUCCESS,
                    payload: mqttEventChannelResult.payload,
                  });
                }
                break;
              case `/supplier_disbursement/${process.env.REACT_APP_TOPIC_ENV}/${merchantId}/`:
                  const supplierDisbursement = {...mqttEventChannelResult.payload.data};
                  delete supplierDisbursement.username
                  const supplierDisbursements = yield select(state => state.Transactions.supplierDisbursements);
                  if (supplierDisbursement.update) {
                    delete supplierDisbursement.update
                    const newSupplierDisbursements = supplierDisbursements.data.map(e => {
                      if (e.disbursement.disbursementId === supplierDisbursement.disbursementId) {
                        e.disbursement = supplierDisbursement
                      }
                      return e
                    })
                    yield put({
                      type: trxAction.SET_SUPPLIER_DISBURSEMENTS,
                      payload: {...supplierDisbursements, data: newSupplierDisbursements},
                    });
                    yield put({
                      type: trxAction.UPDATE_SUPPLIER_DISBURSEMENT_SUCCESS,
                      payload: mqttEventChannelResult.payload.data
                    });
                  } else {
                    const disbursement = supplierDisbursement.disbursement
                    if (
                      !supplierDisbursements.searchWord ||
                      disbursement.companyName.includes(supplierDisbursements.searchWord) ||
                      disbursement.disbursementId.includes(supplierDisbursements.searchWord)
                    ) {
                      const newSupplierDisbursements = supplierDisbursements?.data ?
                        [supplierDisbursement, ...supplierDisbursements.data].reduce((newArr, it) => {
                          const isExist = newArr.find(item => item.disbursement.disbursementId === it.disbursement.disbursementId)
                          if(!isExist) {
                            newArr.push(it);
                          }
                          return newArr;
                        },[])
                        : [supplierDisbursement]
                      yield put({
                        type: trxAction.SET_SUPPLIER_DISBURSEMENTS,
                        payload: {
                          data: newSupplierDisbursements,
                          currentCount: supplierDisbursements.currentCount + 1,
                          totalCount: supplierDisbursements.totalCount + 1,
                          searchWord: supplierDisbursements.searchWord,
                        },
                      });
                    }
                    yield put({
                      type: trxAction.CREATE_SUPPLIER_DISBURSEMENT_SUCCESS,
                      payload: mqttEventChannelResult.payload.data
                    });
                  }
                  break;
              default:
                if (topicsToSubscribe.some(topic => topic === mqttEventChannelResult.topic)) {
                  if (mqttEventChannelResult.payload){
                    yield put(messageArrived(mqttEventChannelResult.payload));
                  }
                  if (role !== 'Pre Registered') {
                    // GET DASHBOARD COUNT
                    yield put({
                      type: dashBoardAction.LOAD_DASHBOARD_DATA,
                    });
                  }
                }
                break;
            }
          }
        } catch (err) {
          console.error('socket error:', err)
        }
      }
    } catch (error) {
      console.warn('Mqtt Catch Error:', error)
      yield put({
        type: actions.CONNECT_MQTT,
        payload: payload,
      });
    }
  });
}

export function* disconnectToMqtt (){
  yield takeEvery(authAction.LOGOUT, function* () {
    const mqttClient = yield select(state => state.MqttReducer.mqttClient);
    if (mqttClient) {
      mqttClient.end(true, () => {
        console.warn('MQTT Disconnected');
      });
      yield put({
        type: actions.SET_MQTT_CLIENT,
        client: null,
      })
    } 
  });
}

function createMQTTEventHandlerNew(client) {
  return eventChannel(emitter => {
    client.on('message', (topic, message) => {
      const payload = JSON.parse(message.toString());
      emitter({ payload , topic })
    });
    return () => false;
  });
}

function resubscribeToMQTT(mqttClient, topicsToResubscribe) {
  const topicNeedToSubcribe = topicsToResubscribe.filter(searchString => 
    !Object.values(mqttClient.messageIdToTopic).some(array => array.includes(searchString))
  );
  if (topicNeedToSubcribe.length > 0) {
    mqttClient.subscribe(topicNeedToSubcribe);
    return true;
  } else {
    return false;
  }
}

function* handleMqttConnection(client) {
  yield new Promise((resolve, reject) => {
    client.on('connect', () => {
      resolve();
    });

    client.on('error', (error) => {
      console.warn("MQTT client error:");
      reject(error);
    });
  });
}

function handleMqttUnsubscribe(client, topic, incomingTopic){
  if (topic === incomingTopic) {
    client.unsubscribe(topic, ()=> {
    });
    for (let [topicsID, arrayOftopics] of Object.entries(client.messageIdToTopic)) {
      client.messageIdToTopic[topicsID] = arrayOftopics.filter(eachTopic => eachTopic !== topic);
      if (client.messageIdToTopic[topicsID].length === 0) {
        delete client.messageIdToTopic[topicsID];  
      }
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(disconnectToMqtt),
    fork(newConnectToMQTT),
  ]);
}
