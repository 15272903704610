
const actions = {
  CONNECT_MQTT: 'CONNECT_MQTT',
  SET_MQTT_CLIENT: 'SET_MQTT_CLIENT',

  connectMqtt: (payload) => ({ 
    type: actions.CONNECT_MQTT,
    payload, 
  }),
};
export default actions;
